<template>
  <div style="height: 40vh;">

    <v-col v-if="Object.keys(inscripcion).length != 0" cols="6" class="mx-auto" >
      <h1><b>Registro procesado</b></h1>
      <br>
      <h3>Curso: <b>{{ inscripcion.curso.name }}</b></h3>
      <h3>Método de pago: <b>{{ inscripcion.metodo_pago }}</b></h3>
      <div class="mt-4" v-if="metodopago == 'Efectivo'">
      {{ mensajeEfectivo}}
      </div>
    </v-col>

  </div>
</template>

<script>
import {  mapMutations, mapGetters } from 'vuex'
import Swal from "sweetalert2";

export default {
  name:'successPayment',
  components: {

  },
  data() {
    return {
      inscripcion: {},
    };
  },
  created() {
    window.scrollTo(0,0)
    this.changeLoading(true)
    //merchant_order_id
    let obj = {
        curso_id : this.$route.params.curso_id,
        users_id : this.$route.params.users_id,
        inscripcion_id : this.$route.params.inscripcion_id,
        payment_id: this.$route.query.payment_id ?? null,
        metodo_pago: this.$route.query.metodopago
      }
   
      this.ACTIVATE_INSCRIPCION(obj)
  },
  mounted(){
    
  },
  computed:{
    ...mapGetters('home',['mensajeEfectivo']),
    metodopago(){
      return this.$route.query.metodopago
    },

  },
  methods:{
    ...mapMutations('home',['changeLoading']),
    async ACTIVATE_INSCRIPCION (obj){
      try{
        let response = null
        if (this.$route.query.pago_case == 'Curso') {
          response = await this.$store.dispatch("register/ACTIVATE_INSCRIPCION",obj); 
        }else{
          response = await this.$store.dispatch("register/ACTIVATE_UNIDAD",obj); 
        }
        this.changeLoading(false)
        if (response) {
          if (response.data.status) {
            this.inscripcion = response.data.inscripcion
            if (this.$route.query.metodopago != 'Efectivo') {
               this.$router.push('/user/home')
              Swal.fire('Registro Procesado', 'Te redirigiremos a tus cursos', 'success')
            }
          }
        }
      }catch(e){
        this.changeLoading(false)
        if (!e.response.data.status) {
          Swal.fire('Error', e.response.data.mensaje, 'error')
          this.$router.push(`/failure/${this.$route.params.curso_id}/${this.$route.params.users_id}/${this.$route.params.inscripcion_id}`)
        }
      }
    }
  }
};
</script>

<style>
</style>