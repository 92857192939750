<template>
  <v-col cols="12" sm="6" class="mx-auto">

  <v-card class="mx-auto">
    <v-card-text>

      <p class="display-1 text--primary">
        Busca tu certificado
      </p>

      <div class="text--primary">
        <v-form ref="form">
          <v-text-field
            v-model="dni"
            :rules="dniRules"
            label="DNI"
            type="number"
          ></v-text-field>
          <v-select
            :items="cursos"
            item-text="name"
            item-value="id"
            v-model="cursos_id"
            label="Curso"
          ></v-select>
        </v-form>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn
        dark
        color="#5ebd99"
        :loading="loading"
        @click="validate"
      >
        Buscar
      </v-btn>
    </v-card-actions>

   
  </v-card>

  </v-col>
</template>

<script>
import { mapState } from 'vuex'
import Swal from "sweetalert2";

export default {
  name:'searhcertificado',
  components: {

  },
  data() {
    return {
      dni: '',
      dniRules: [
        v => !!v || 'El dni es requerido',
      ],
      cursos_id: '',
      loading: false
    };
  },
  created() {
    window.scrollTo(0,0)
  },
  mounted(){
  },
  computed:{
    ...mapState('home',['cursos']),
  },
  methods:{
    async certificado(data){
      try{
        const response = await this.$store.dispatch("home/SEARCHCERTIFICADO", data);
        if(response.status){
          this.$router.push({name:'certificado', params: {dni: this.dni, tipo: 'AT', id: response.certificado}})
        }else{
          Swal.fire(':(', response.mensaje, 'error')
        }
        this.loading = false
      }catch (e){
        console.log(e)
      }
    },
    validate(){

      if (this.$refs.form.validate()) {
        this.loading = true
        var data = {
          dni: this.dni,
          cursos_id: this.cursos_id,
        }
        this.certificado(data)
      }
    }
  }
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>