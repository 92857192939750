<template>
  <div>
  <section>
    <span class="px-4 mt-6 text-center py-6 medium font-size-sub-title">
          <div class="d-flex justify-center">
            <div class="text-center">
              <v-img src="../../assets/images/house contacto.svg" width="100%"></v-img>
            </div>
            <div v-html="direccion">
              
            </div>
          </div>
          <div>
            <v-spacer class="mb-10"></v-spacer>
            <v-avatar size="40" tile>
              <v-img src="../../assets/images/call contacto.svg"></v-img>
            </v-avatar>{{ phone }} - {{ whatsapp }}
            <v-spacer class="mb-10"></v-spacer>
          </div>
        </span>
  </section>
  <section id="map">
      <v-container fluid class="py-0 px-0">
        <v-row>
          <v-col cols="12" v-html="mapUbication">
          </v-col>
        </v-row>
      </v-container>
    </section>
    </div>
</template>

<script>
import {  mapGetters } from 'vuex'

export default {
  name:'ubication',
  components: {

  },
  data() {
    return {
    };
  },
  created() {
    window.scrollTo(0,0)
    
  },
  mounted(){
  },
  computed:{
    ...mapGetters('home',['mapUbication', 'direccion','phone','whatsapp']),

  },
  methods:{
    
  }
};
</script>

<style>
</style>