<template>
  <div style="height: 40vh;">
   
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Swal from "sweetalert2";

export default {
  name:'successPayment',
  components: {

  },
  data() {
    return {
    };
  },
  created() {
    window.scrollTo(0,0)
    this.changeLoading(true)
    //merchant_order_id
    this.DELETE_INSCRIPCION({
      curso_id : this.$route.params.curso_id,
      users_id : this.$route.params.users_id,
      inscripcion_id : this.$route.params.inscripcion_id,
    })
  },
  mounted(){
    
  },
  computed:{
    
  },
  methods:{
    ...mapState('home',['changeLoading']),
    async DELETE_INSCRIPCION(obj){
      try{
        const response = await this.$store.dispatch("register/DELETE_INSCRIPCION",obj); 
        if (response.data.status) {
          this.$router.push('/user/home')
          Swal.fire('Registro fallido', 'Ocurrio un error, intenta nuevamente', 'error')
        }
      }catch(e){
        console.log(e.response.data)
      }
    }
  }
};
</script>

<style>
</style>