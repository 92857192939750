<template>
  <v-container fluid class="px-0 py-0">
    <Compartir
    :name="item_course.name"
    :slug="item_course.slug"
    ></Compartir>
    <section id="title-card">
      <v-row>
        <v-col cols="12" class="px-0 py-0">
          <v-img
            gradient="to top, #0000003b, #000000c9"
            class="white--text align-center  position-img"
            :src="URL_IMG+item_course.img"
            height="200"
          >
          <div class="col-sm-9 mx-auto flex-column flex-sm-row d-flex justify-space-between">
            
          
            <div class="col-sm-9 col-12 btn-incr">
              <p class=" display-1-text text-center text-sm-left  font-weight-bold mb-0">
                {{ item_course.name }}
              </p>
               <p class="text-center text-sm-left " v-if="item_course.area">
                {{ item_course.area.description }}
              </p>
            </div>
            <!-- <div class="col-sm-3 col-12 btn-incr">
              <router-link class="link-dark" :to="'/inscripcion?course='+item_course.name">
                <v-btn  block large color="#4CAF50" dark class=""><span style="font-weight: bold">Inscribirse</span></v-btn>
              </router-link>
            </div> -->
            </div>
          </v-img>
          
        </v-col>
      </v-row>
    </section>
    <v-row>
      <v-col cols="12" sm="8" class="mx-aut offset-sm-1">
        <v-row>
          <section class="col-12">
            <v-row>
              <v-col cols="12" v-if="item_course.video">
                <video width="100%"  controls="" autoplay="" :src="URL_IMG+item_course.video"></video>
              </v-col>

              <v-col cols="12" class="d-flex justify-center flex-wrap" v-if="false">
              <h2 class="mb-3">Fechas de inicio</h2>

              <v-expansion-panels multiple focusable>
                <v-expansion-panel v-for="(item,ik) in item_course.tipo_inscripcion" :key="ik" >
                  <v-expansion-panel-header>
                    {{ item.tipo[0].name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list-item v-for="(i, key) in item.fechas"  :key="key" class="text-left">
                      <v-list-item-content>
                        <v-list-item-title class="font-size-card">
                          <b>Inicia:</b> {{ i.fecha | date}} - {{ i.horario }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>


              </v-col>
              <v-col cols="12" v-html="item_course.descripcion"></v-col>

              
              <v-col cols="12" v-if="false" class="py-0 px-0 mt-10">
                <v-card color="grey lighten-5 text-center py-7" flat>
                  <p class="font-weight-bold display-1-text">
                    En este curso aprenderás...
                  </p>
                </v-card>
              </v-col>
              <v-container v-if="false">
                <v-col cols="12">
                  <!-- UNIDADES -->
                  <v-expansion-panels v-if="isUnidades" focusable>
                    <v-expansion-panel 
                      v-for="(item,i) in item_course.unidades"
                      :key="i">
                      <v-expansion-panel-header>
                       <b style="color: #1B5E20"> {{ item.name }}</b>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="mt-5" v-if="item.video_public == 1">
                      <video controls="" width="100%" :src="URL_IMG+item.video"></video>
                    </div>

                        <div class="pt-4" v-html="item.description"></div>
                        <br>
                        <v-chip class="" label >
                          Arancel: ${{ item.monto }}
                        </v-chip>
                        
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <div v-else>
                    <b style="color: #1B5E20" class="mb-5 d-block"> {{ unaUnidad.name }}</b>
                    <div v-if="unaUnidad.video_public == 1">
                      <video controls="" width="100%" :src="URL_IMG+unaUnidad.video"></video>
                    </div>
                    <div v-html="unaUnidad.description"></div>
                    <v-chip class="mt-5" label >
                      Costo de unidad: ${{ unaUnidad.monto }}
                    </v-chip>
                  </div>
                </v-col>
              </v-container>
            </v-row>
          </section>
          
          <!--  -->
          <v-card color="grey lighten-5 text-center py-7 col-12 mt-10" flat>
            <p class="font-weight-bold display-1-text">
              Formulario de contacto
            </p>
          </v-card>

          <div class="px-5">
            <formularioContacto
              :curso="item_course.id"
            ></formularioContacto>
          </div>
          <!-- CAROUSEL -->
          <section id="cualidades_descripcion" class="mt-10 col-12" v-if="isGalery">
            <v-row align="center" justify="center">
              <v-col cols="12" class="py-0">
                <v-carousel hide-delimiters height="350">
                  <v-carousel-item
                    v-for="(item, i) in item_course.galeria"
                    :key="i"
                    :src="URL_IMG+item.archivo"
                  ></v-carousel-item>
                </v-carousel>
              </v-col>
            </v-row>
          </section>
          <!--  -->
          <section id="tabs-cursos" class="col-sm-6" style="display: none">
            <v-card flat class="pt-12 bg-brocha">
              <span class="px-4 py-4 text-center justify-center py-6">
                <p class="font-weight-bold display-1-text px-3">
                  Actividades promocionales
                </p>
              </span>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-tabs
                      v-model="tab"
                      background-color="transparent"
                      color="black"
                      centered
                    >
                      <v-tabs-slider color="black"></v-tabs-slider>
                      <v-tab
                        v-for="item in items_tabs"
                        :key="item"
                        class="text-capitalize bold"
                      >
                        <span class="font-weight-bold">{{ item }}</span>
                      </v-tab>
                    </v-tabs>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4"  v-for="item in items_tabs_content" :key="item.color">
                          <v-card style="border-radius: 30px" :color="item.color" dark >
                            <v-toolbar  dense  flat  dark  color="#3D3D3D"  style="border-radius: 100px"  class="text-center">
                              <v-spacer></v-spacer>
                              <span>{{ item.title }}</span>
                              <v-spacer></v-spacer>
                            </v-toolbar>
                            <div class="px-4 py-4 text-center">
                              <span>{{ item.text }}</span>
                              <v-spacer></v-spacer>
                              <span>
                                <span class="font-weight-bold">Inicia:</span>
                                {{ item.start }}
                              </span>
                              <v-spacer></v-spacer>
                              <span>
                                <span class="font-weight-bold">Duración:</span>
                                {{ item.duration }}
                              </span>
                              <v-divider
                                class="my-5"
                                style="border: 1px solid #eae0e0bf"
                              ></v-divider>
                              <span class="font-weight-bold"
                                >Inscripción: $ {{ item.inscription }}</span
                              >
                              <v-spacer></v-spacer>
                              <span class="font-weight-bold"
                                >Cuota mensual: $ {{ item.monthly_fee }}</span
                              >
                              <v-spacer></v-spacer>
                              <span>{{ item.extra }}</span>
                              <span></span>
                            </div>
                          </v-card>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              :color="item.color"
                              rounded
                              class="mt-5 text-capitalize px-5"
                              large
                            >
                              <span class="font-weight-bold">Inscribete</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-col>
                      </v-row>
                    </v-container>
                    <!-- </v-card> -->
                    <!-- <v-card flat v-if="item==='Caracteristicas'">
                          <v-container>
                            <v-row justify="center">
                              <v-col cols="12" sm="8">
                                <v-simple-table style="border: 1.5px solid #efefef">
                                  <template v-slot:default>
                                    <tbody>
                                      <tr v-for="item in desserts" :key="item.name">
                                        <td>
                                          <v-avatar size="20" class="mr-3" tile>
                                            <v-img :src="item.icon" contain></v-img>
                                          </v-avatar>
                                          {{ item.name }}
                                        </td>
                                        <td class="text-right">{{ item.value }}</td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-col>
                            </v-row>
                          </v-container>
                    </v-card>-->
                    <!-- </v-tab-item> -->
                    <!-- </v-tabs-items> -->
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </section>
        </v-row>
      </v-col>
      <v-col cols="12" sm="3" class="top-sticky" style="">
        <div style=" " class="sticky-top">
          <v-card-actions class="px-0">
            <v-btn rounded block large color="#4CAF50" dark class="" @click="inscripcion()" v-if="item_course.tipo_inscripcion_form == 'Manual'"><span style="font-weight: bold">Inscribirse</span></v-btn>

            <router-link class="w-100" 
              :to="`/register-course?course=${item_course.slug}&area_id=${item_course.areas_id}`" v-else ><v-btn rounded block large color="#4CAF50" dark class=""><span style="font-weight: bold">Inscribirse</span></v-btn></router-link>
          </v-card-actions>

          <v-simple-table class="mb-8" v-if="false">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Próximas Fechas
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in item_course.fechas" :key="key" >
                  <td>{{ item.fecha | date }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
    <!--  -->
    <section id="cursos relacionados">
      <v-card flat class="pt-12" color="transparent">
        <span class="px-4 py-4 text-center justify-center py-6">
          <p class="font-weight-bold display-1-text px-3">
            Cursos relacionados
          </p>
        </span>
        <v-container>
          <v-row>

            <v-col cols="12" sm="12" md="4" class="pr-5"
            v-for="(item, key) in relacionados"
            :key="key">

              <boxCurso
                :item="item"
              ></boxCurso>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </section>
  </v-container>
</template>
<script>
import formularioContacto from '../../components/formularioContacto.vue'
import boxCurso from '../../components/boxCurso.vue'
import Compartir from '../../components/Compartir.vue'
import { mapState, mapMutations } from 'vuex'
import moment from "moment";

export default {
  components: {
    formularioContacto,
    boxCurso,
    Compartir
  },
  data() {
    return {
      url: '',
      item_course: {},
      relacionados: [],
      items_tabs: ["Descripción"],
      items_tabs_content: [
        {
          title: "Modalidad Regular Zoom",
          text: "Se cursa una vez por semana mediante la plataforma zoom",
          start: "Jueves 13 de Agosto de 15:00 a 16:00 Hs",
          duration: "4 meses",
          inscription: 250,
          monthly_fee: "$1550.- c/u.",
          extra: "Al finalizar el curso se abona $550 por derecho de examen",
          color: "#5EBD99",
        },
        {
          title: "Modalidad Intensiva Zoom",
          text: "Se cursa una vez por semana mediante la plataforma zoom",
          start: "Jueves 13 de Agosto de 15:00 a 16:00 Hs",
          duration: "4 meses",
          inscription: 250,
          monthly_fee: "$1550.- c/u.",
          extra: "Al finalizar el curso se abona $550 por derecho de examen",
          color: "#4C8AFF",
        },
        {
          title: "Modalidad Regular Zoom",
          text: "Se cursa una vez por semana mediante la plataforma zoom",
          start: "Jueves 13 de Agosto de 15:00 a 16:00 Hs",
          duration: "4 meses",
          inscription: 250,
          monthly_fee: "$1550.- c/u.",
          extra: "Al finalizar el curso se abona $550 por derecho de examen",
          color: "#C381F6",
        },
      ],
      tab: null,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      items: [
        {
          title: 'Click Me',
        },
        {
          title: 'Click Me',
        },
        {
          title: 'Click Me',
        },
        {
          title: 'Click Me 2',
        },
      ],
      select: [
        { text: 'State 1' },
        { text: 'State 2' },
        { text: 'State 3' },
        { text: 'State 4' },
        { text: 'State 5' },
        { text: 'State 6' },
        { text: 'State 7' },
      ],
      
    }
  },
  metaInfo () {
    return {
      title: this.item_course.name+'- IDIP',
      meta: [
        { name: 'description', content: this.item_course.subtitulo ? this.item_course.subtitulo : this.item_course.name },
        {property: "og:url"           ,content: `${this.url}` },
        {property: "og:type"          ,content: "website"},
        {property: "og:title"         ,content: this.item_course.name},
        {property: "og:description"   ,content: this.item_course.subtitulo ? this.item_course.subtitulo : ''},
        {property: "og:image"         ,content: this.URL_IMG+this.item_course.img},
      ]
    }
  },
  filters: {
    date(value) {
      return moment(value).locale("es").format("DD/MM/YYYY");
    },
  },
  created() {
    window.scrollTo(0,0)
    this.changeLoading(true)
    if (this.$route.params.preview) {
      this.coursePreview(this.$route.params.area, this.$route.params.area_id, this.$route.params.slug, this.$route.params.preview);
    }else{
      this.course(this.$route.params.area,this.$route.params.area_id, this.$route.params.slug);
    }
    
  },
  mounted(){

  },
  beforeRouteUpdate (to, from, next) {
    this.changeLoading(true)
    this.course(to.params.area,to.params.area_id, to.params.slug)
    next()
  },
  computed: {
    ...mapState(['URL_IMG']),
    isCurso(){
      if (Object.entries(this.item_course).length != 0) {
        return true
      }
      return  false
    },
    isFecha(){
      if (this.isCurso) {
        if (this.item_course.area.isFecha == 1) {
          return true
        }
      }
      return false
    },
    isGalery(){
      if (this.isCurso) {
        if (this.item_course.galeria.length > 0) {
          return true
        }
      }
      return false
    },
    isUnidades(){
      if (this.isCurso) {
        if (this.item_course.unidades.length > 1) {
          return true
        }
      }
      return false
    },
    unaUnidad(){
      if (this.isCurso) {
        if (!this.isUnidades) {
          if(this.item_course.unidades[0] != undefined){
            return {
              name: this.item_course.unidades[0].name,
              description: this.item_course.unidades[0].description,
              monto: this.item_course.unidades[0].monto,
              video:  this.item_course.unidades[0].video,
              video_public:  this.item_course.unidades[0].video_public

            }
          }
        }
      }
      return {name:'',description:'',monto:''}
    }
    // Is_Authenticated() {
    //   return this.$store.getters["auth/AUTHENTICATED"];
    // },
  },
  methods: {
    ...mapMutations('home',['changeLoading']),
    inscripcion(){
      this.$router.push('/inscripcion?course='+this.item_course.name)
    },
    async course(area,area_id,slug) {
      try {
        const response = await this.$store.dispatch("course/SHOW", {area:area, area_id: area_id, slug:slug});
        this.item_course = response.curso;
        this.relacionados = response.relacionados
        this.changeLoading(false)
        window.scrollTo(0,0)
      } catch (e) {
        if (e.response.status == 301) {
          this.$router.push('/')
        }
      }
    },
    async coursePreview(area,area_id,slug, preview){
      try {
        const response = await this.$store.dispatch("course/PREVIEW", {area:area, area_id:area_id, slug:slug, preview:preview});
        this.item_course = response.curso;
        this.relacionados = response.relacionados
        this.changeLoading(false)
        window.scrollTo(0,0)
      } catch (e) {
        if (e.response.status == 301) {
          this.$router.push('/')
        }
      }
    }
  },
};
</script>
<style lang="scss">
.top-sticky{
  @media(min-width: 992px){
    margin-top: -140px
  }
}
.sticky-top{
  @media(min-width: 992px){
    position: sticky; 
  top: 20px;
  height: 100vh;
  overflow-y: scroll;
  }
}
.sticky-top::-webkit-scrollbar {
    display: none;
}
.sticky-top::-moz-scrollbar {
    display: none;
}
.sticky-top::-o-scrollbar {
    display: none;
}

.decoration-through {
  text-decoration: line-through;
}

.border-radius-0 {
  border-radius: 0px !important;
}
.shadow-small {
  box-shadow: 0 2px 4px rgba(27, 31, 35, 0.15) !important;
}

.border-cards {
  border-radius: 20px !important;
}

.border-button {
  border-radius: 50px !important;
  font-size: 16px !important;
}
</style>