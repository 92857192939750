<template>
  <v-col cols="12" sm="8" class="mx-auto">
    <h1 class="mb-5">Preguntas Frecuentes</h1>

    <v-expansion-panels v-model="panel" multiple>

      <v-expansion-panel v-for="(i, key) in preguntasR" :key="key">
        <v-expansion-panel-header>{{ i.pregunta }}</v-expansion-panel-header>
        <v-expansion-panel-content >
          <div v-html="i.respuesta"></div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      
    </v-expansion-panels>
  </v-col>
</template>

<script>

export default {
  name:'ubication',
  components: {

  },
  data() {
    return {
      preguntasR:[],
      panel: null,
    };
  },
  created() {
    window.scrollTo(0,0)
    
    this.preguntas()
  },
  mounted(){
  },
  computed:{

  },
  methods:{
    async preguntas(){
      try{
        const response = await this.$store.dispatch("home/PREGUNTAS");
        this.preguntasR = response.questions
      }catch (e){
        console.log(e)
      }
    }
  }
};
</script>

<style>
</style>