<template>
  <v-container fluid class="py-0">
    <section id="img_principal_corporal">
      <v-row>
        <v-col cols="12" class="px-0 py-0">
          <v-img
            gradient="to top, #0000003b, #000000c9"
            class="white--text align-center position-img"
            :src="URL_IMG+subarea.img"
            height="150"
          >


            <p class="ml-12 display-1-text font-weight-bold mb-0">
              {{ subarea.description }}
            </p>
            <p class="ml-12 ">
              {{ subarea.area.description }}
            </p>
          </v-img>
        </v-col>
      </v-row>
    </section>
    <!--  -->
    <section id="">

    <v-col cols="12" sm="5">
      
   
    <v-tabs v-model="tab" background-color="transparent" color="#5ebd99" grow>
      <v-tab v-for="item in subtipos" :key="item.id">
        <h3>{{ item.description }}</h3>
      </v-tab>
    </v-tabs>
     </v-col>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in subtipos" :key="item.id">
        <v-row>

          <v-col cols="12" sm="6" md="4"  
            v-for="(c, key) in filterCourse(item.id)" :key="key" class="d-flex align-stretch">
            
            <boxCurso
              :item="c"
            ></boxCurso>

          </v-col>

        </v-row>
      </v-tab-item>
    </v-tabs-items>


    </section>
    <!--  -->
    <section id="cursos_personalizados">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="8" v-html="body_area">
          
        </v-col>
        <v-col cols="12" sm="12" md="6">
          
          <v-btn
            color="primary"
            small
            block
            @click="benefit"
            class="mt-10 border-button-small d-flex d-sm-none"
            height="60px"
          >¡Conoce nuestras promociones!</v-btn>
          <v-btn
            color="primary"
            block
            large
            @click="benefit"
            class="mt-10 border-button d-none d-sm-flex"
            height="60px"
          >¡Conoce nuestras promociones!</v-btn>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import boxCurso from '../../components/boxCurso.vue'

export default {
  components:{
    boxCurso
  },
  data() {
    return {     
      cursos_personalizados: [
        "Cursalo en forma intensiva",
        "Elegí el horario de tu preferencia y la cantidad de horas diarias",
        "Días a elección de Lunes a Sábados",
        "Toda la práctica que necesites estará a su alcance",
        "Obtené todas las respuestas a las preguntas que formules",
        "Capacítate en forma individual o grupal (si queres asistir con otras personas)",
        "Obtené en pocos días el mismo conocimiento que si lo cursaras en meses con la modalidad tradicional",
        "Aranceles accesibles, incluyen material de estudio y Certificado",
        "Dictados de cursos por profesionales en actividad",
      ],
      tab: 0,
    };
  },
  metaInfo () {
    return {
      meta: [
        { name: 'description', content: this.subarea.description }
      ]
    }
  },
  computed:{
    ...mapState(['URL_IMG']),
    ...mapState('home',['subarea','subtipos']),
    ...mapGetters('home',['body_area'])
  },
  created(){
    this.changeLoading(true)
    window.scrollTo(0,0)
    this.subareaGet(this.$route.params)
  },
  beforeRouteUpdate (to, from, next) {
    this.changeLoading(true)
    this.subareaGet(to.params)
    next()
  },
  methods: {
    ...mapMutations('home',['changeLoading']),
    filterCourse(subtipo){
      // this.subarea.cursos
      if(Object.entries(this.subarea).length > 0){
        var arr = []
        this.subarea.cursos.filter((element)=>{
          if (element.subtipo.id == subtipo) {
            arr.push(element)
          }
        })
      }
      return arr
    },
    async subareaGet(slug){
      try{
        await this.$store.dispatch("home/SUBAREA", slug);
        this.changeLoading(false)
      }catch (e){
        console.log(e)
      }
    },
    itemsTabs(indice) {
      if (indice === 0) {
        return this.item_sabado_mes;
      } else if (indice === 1) {
        return this.item_1_por_semana;
      } else if (indice === 2) {
        return this.item_jornadas;
      }
    },
    benefit() {
      this.$router.push({ name: "benefit" });
    },
  },
};
</script>
<style>
.border-radius-0 {
  border-radius: 0px !important;
}
.shadow-small {
  box-shadow: 0 2px 4px rgba(27, 31, 35, 0.15) !important;
}

.border-cards {
  border-radius: 20px !important;
}

.border-button {
  border-radius: 50px !important;
  font-size: 16px;
}

.border-button-small {
  border-radius: 50px !important;
}
</style>