<template>
    <v-container>
    <v-row>
      <v-col cols="12" sm="8" md="5" class="mx-auto">

        <v-card elevation="3" >
          <v-card-text>
              <googlelogin v-if="isGoogleLogin" v-on:datosuser="loginSocial"></googlelogin>
              <facebooklogin v-if="isFacebookLogin" v-on:datosuser="loginSocial"></facebooklogin>
              <div v-if="socialLogin" class="col-12 text-center"> O</div>
              <v-divider v-if="socialLogin"></v-divider>
              <v-col cols="12">
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-text-field
                    v-model="email"
                    name="email"
                    :rules="emailRules"
                    label="Correo Electrónico"
                    @keyup.enter="validate"

                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="password"
                    name="password"
                    :rules="passwordRules"
                    label="Contraseña"
                    type="password"
                    @keyup.enter="validate"

                    required
                  ></v-text-field>

                  <v-btn
                    :disabled="!valid"
                    color="success"
                    class="d-block col-6 mx-auto"
                    :loading="loading"
                    @click="validate"
                  >
                    Ingresar
                  </v-btn>

                  
                </v-form>
              </v-col>
              <br>
              <v-divider></v-divider>
              <div class="text-center mt-5">
                <p>¿No tienes cuenta? <router-link :to="ruta">Registrate</router-link></p>
              </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  
</template>

<script>
import { mapMutations, mapState, mapGetters} from 'vuex'
import googlelogin from '../../components/GoogleLoginComponent.vue'
import facebooklogin from '../../components/FacebookLoginComponent.vue'
import toastr from 'toastr'
export default {
  name: "Register",
  components: {
    googlelogin, facebooklogin
  },
  props: {
    
  },
  data() {
    return {
      password: '',
      passwordRules:[
        v=> !!v || 'La contraseña es requerida',
      ], 
      loading: false,
      valid: true,
      email: '',
      emailRules: [
        v => !!v || 'El correo es requerido',
        v => /.+@.+\..+/.test(v) || 'El correo es invalido',
      ], 
    };
  },
  computed: {
    ...mapState('home',['courseRegister']),
    ...mapGetters('home',['isFacebookLogin','isGoogleLogin']),
    ruta(){
      if (this.$route.query.course) {
        return '/register?course='+this.$route.query.course
      }
      return '/register'
    }, 
    socialLogin(){
      if (this.isFacebookLogin || this.isGoogleLogin) {
        return true
      }
      return false
    }
  },
  created(){
    window.scrollTo(0,0)
    this.llenarCourseRegister(this.$route.query.course)
  },
  mounted() {
  
  },
  methods: {
    ...mapMutations('home',['llenarCourseRegister']),
    ...mapMutations('auth',['ADD_TOKEN']),
    loginSocial(data){
      this.email = data.email
      this.password = data.id
      this.loginRedSocial()
    },
    clearData(){
      this.password       = ''
      this.email          = ''
    },
    async loginRedSocial(){
      try{
        var obj = {
          email: this.email,
          curso: this.courseRegister,
          password: this.password
        }
        const response = await this.$store.dispatch("auth/LOGIN",obj);
        if (response.data.status) {
          this.ADD_TOKEN(response.data.token)
          if (this.courseRegister) {
            this.$router.push('/register-course?course='+this.courseRegister+'&area_id='+this.$route.query.area_id)
          }else{
            this.$router.push('/user/home')
          }
        }
      }catch (e){
        console.log(e) 
      }
    },
    async login(){
      try{
        var obj = {
          password: this.password,
          email: this.email,
          curso: this.courseRegister,
          social: false
        }
        const response = await this.$store.dispatch("auth/LOGIN",obj);
        if (response.data.status) {
          this.clearData()
          this.ADD_TOKEN(response.data.token)
          if (this.courseRegister) {
            this.$router.push('/register-course?course='+this.courseRegister+'&area_id='+this.$route.query.area_id)
          }else{
            this.$router.push('/user/home')
          }
        }
      }catch (e){
        var response = e.response.data.errors
        for (const prop in response) {
          toastr.error(response[prop][0])
        } 
      }
    },
    validate () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.login()
    },
  },
};

</script>
<style lang="scss">
    @import 'node_modules/toastr/toastr.scss';
    .icon-social{
      width: 20px;
    }
    .google-signin-btn{
      width: 100%;
      background: #f5f5f5;
      color: rgba(0, 0, 0, 0.87);
      padding: 7px;
      text-align: center;
      display: flex;
      justify-content: center;
      font-size: 1rem;
      text-transform: uppercase;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
      border-radius: 4px;
      margin-bottom: 12px;
      &:focus{
        border: 0px;
        box-shadow: none;
        outline: none;
      }
    }
</style>