<template>
  <div class="my-5 container">
    <h1 class="font-weight-bold mb-4" v-if="user">Hola {{ user.name }}</h1>

    <v-tabs v-model="tab" align-with-title >
      <v-tabs-slider color="rgb(94, 189, 153)"></v-tabs-slider>
      <v-tab v-for="item in areasCursos" :key="item.id">
        {{ item.description }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in areasCursos" :key="item.id">
        <v-row class="mt-8">
          <v-col
            cols="12"
            sm="6"
            md="4"
            v-for="(item, key) in item.cursos"
            :key="key"
            class="d-flex align-stretch"
          >
            <boxCurso
              :item="item"
              :user="true"
            ></boxCurso>
              
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import boxCurso from '../../components/boxCurso.vue'

export default {
  name:'courses-list',
  components: {
    boxCurso  
  },
  data() {
    return {
      tab: null,
    };
  },
  created() {
    this.courses()
  },
  mounted(){
  },
  computed:{
    ...mapState('home',['cursosList','areas']),
    ...mapGetters('home',['areasCursos']),
    ...mapState('auth',['user']),
  },
  methods:{

    async courses(){
      try{
        await this.$store.dispatch('home/CURSOS')
      }catch(e){
        console.log(e)
      }
    }
  }
};
</script>

<style>
</style>