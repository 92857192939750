<template>
  <div class="container">
    <h1 v-if="isTitle == 1">{{ title }}</h1>
    <div v-html="description"></div>
  </div>
</template>

<script>


export default {
  name:'customPage',
  components: {

  },
  data() {
    return {
      title: '',
      description: '',
      isTitle: null
    };
  },
  created() {
    window.scrollTo(0,0)

    this.custom_page(this.$route.params.custompage)

  },
  mounted(){
  },
  computed:{

  },
  methods:{
    async custom_page(slug){
      try{
       const response = await this.$store.dispatch("home/CUSTOM_PAGE", slug);
       this.title       = response.page.title
       this.description = response.page.description
       this.isTitle = response.page.is_title
       console.log(response.page)
      }catch (e){
        this.$router.push('/')
        console.log(e.response.data.status)
      }
    },

  }
};
</script>

<style>
</style>