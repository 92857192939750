<template>
  <div class="contButonShare">
    <div id="fb-root"></div>
    <div  class="fb-share-button" 
          data-href="https://developers.facebook.com/docs/plugins/" 
          data-layout="button_count" 
          data-size="large">
            <a target="_blank" 
            :href="url" 
            class="fb-xfbml-parse-ignore facebookShare">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ffffff" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
            </a>
    </div>
    <a :href="urlT" class="twitterShare" target="_blank">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ffffff" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
    </a>
  </div>
</template>
<script async defer crossorigin="anonymous" src="https://connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v9.0&appId=451676575798804&autoLogAppEvents=1" nonce="rEnBlc6V"></script>
<script>
// import { mapState } from 'vuex'
export default {
  name: 'share',
  props:{
    name:{
      type:String
    },
    slug:{
      type: String
    }
  },
  watch:{
    slug: 'changeSlug'
  },
  components: {

  },
  data(){
    return{
      url: 'https://www.facebook.com/sharer/sharer.php?u=',
      urlT: 'https://twitter.com/intent/tweet?'
    }
  },
  computed:{

  },
  created(){
   
  },
  mounted(){
    
  },
  methods:{
    changeSlug(){

      const urlactual = window.location;
      var append = '&src=sdkpreparse'
      this.url= this.url+urlactual+'.html'+append
      var text = 'text='+this.name
      var urll = '&url='+urlactual+'.html'
      this.urlT = this.urlT+text+urll
    }
  }
}
</script>

<style lang="scss">
  @mixin button(){
    width: 40px;
    height: 40px;
    overflow: hidden;
    display: block;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contButonShare{
    position: fixed;
    left: 0px;
    top: 170px;
    z-index: 9;
    .fb-share-button{
      a.facebookShare{
        @include button;
        background: #3b5998;
        &:hover{
          background: #386890;
        }
      }
    }
    a.twitterShare{
      @include button;
      background: #00acee;
      &:hover{
        background: #008abe;
      }
    }
  }
</style>
