
<template>
  <div class="container">
   <h1><b>{{ name }}</b></h1>
    <v-list>
   <v-list-item
        v-for="(item, i) in documento"
        :key="i"
        class="item-unidad"
        title="Descargar"
        @click="descargarDocumento(item.enlace, item.fileu)"
      >
        <v-list-item-content >
          <v-list-item-title ><b>{{ item.description }}</b></v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon >
            mdi-download
          </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Swal from "sweetalert2";

export default {
  name:'document_public',
  components: {

  },
  data() {
    return {
      isDocumento: false,
      name: null, 
      documento: [],
    };
  },
  created() {
    if (this.$route.query.documento) {
      this.isDocumento = true
    }
    this.document()
  },
  mounted(){
  },
  computed:{
    ...mapState(['URL_IMG']),
  },
  methods:{
     descargarDocumento(enlace, fileu){
      if (enlace != null) {
        var url = this.URL_IMG+enlace
      window.open(url)
      }
      if (fileu != null) {
        window.open(fileu)
      }
      
    },
    async document(){
      try{
        var obj = {
          unidades_id: this.$route.query.unidad
        }
        if (this.isDocumento) {
          obj.documento = this.$route.query.documento
        }
        const response = await this.$store.dispatch('home/UNIDADES_DOCUMENTS',obj)
        if (response.status) {
          this.name = response.unidad.name
          if (response.documento != null) {
            this.documento.push(response.documento)
          }else{
            this.documento = response.unidad.documentos
          }
        }else{
          Swal.fire('Oops...', response.mensaje, 'error').then(()=>{
            this.$router.push('/')
          })
        }
      }catch(e){
        console.log(e)
      }
    }
  }
};
</script>

<style>
</style>