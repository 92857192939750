<template>
 <GoogleLogin :params="params" class="google-signin-btn" :onSuccess="onSuccess" :onFailure="onFailure">
   <div class="text-left d-flex align-center">
      <img src="../assets/img/Google_icon.svg" class="icon-social mr-4" alt="">
      Iniciar con Google
    </div>
  </GoogleLogin>
</template>

<script>
import GoogleLogin from 'vue-google-login';

export default {
  name: 'googlelog',
  props:{

  },
  components: {
    GoogleLogin
  },
  data(){
    return{
      params: { client_id: process.env.VUE_APP_GOOGLE_KEY },    
    }
  },
  computed:{

  },
  mounted(){

  },
  methods:{
    onSuccess(googleUser){
      console.log(googleUser.getBasicProfile())
      var data = {
        name: googleUser.getBasicProfile().Ve,
        email: googleUser.getBasicProfile().Nt,
        id: googleUser.getBasicProfile().LS
      }
      this.$emit('datosuser', data)
    },
    onFailure(googleUser){
      console.log(googleUser)
    },
  }
}
</script>

<style lang="scss">

</style>
