<template>
 <v-facebook-login 
  :app-id="appid" 
  :version="'v9.0'" 
  :text-class="' text-uppercase'"
  :text-style="{fontSize:'1rem'}"
  class="w-100 d-flex justify-content-center py-1"
  @login="loginfacebook" 
  @sdk-init="handleSdkInit">
  <div slot="logo" class="me-2 d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="white"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg></div>
  <div slot="login">Iniciar con Facebook</div>
</v-facebook-login>
</template>

<script>
import VFacebookLogin from 'vue-facebook-login-component'
export default {
  name: 'FacebookLogin',
  props:{

  },
  components: {
    VFacebookLogin
  },
  data(){
    return{
      FB: {},
      appid: process.env.VUE_APP_FACEBOOK_KEY
    }
  },
  computed:{

  },
  mounted(){

  },
  methods:{
    loginfacebook(){
      this.FB.api('/me?fields=name,email,picture', (response) =>{
        console.log(response)
        var data = {
          name: response.name,
          email: response.email,
          id: response.id
        }
        this.$emit('datosuser', data)
     });
    },
    handleSdkInit({ FB }) {
        this.FB = FB
    },
  }
}
</script>

<style lang="scss">

</style>
