<template>
  <v-container fluid class="px-0 py-0">
    <section id="carrusel">
      <v-carousel hide-delimiters class="carousel" cycle>
        <v-carousel-item
          v-for="(s, key) in slider" :key="key"
          :src="`${URL_IMG}${image(s)}`"
          reverse-transition="fade-transition"
          transition="slide-x-transition"
        >
          
        </v-carousel-item>
      </v-carousel>
    </section>
    <!--  -->
    <section id="cursos-intensivos" class="py-sm-12">
      <v-card flat class="pt-sm-12 bg-brocha py-sm-12 d-flex justify-center align-center flex-column">
        <span v-if="promocionados.length > 0" class="px-sm-4 py-sm-4 text-center justify-center ">
          <p class="font-weight-bold display-1-text px-sm-3">{{ titulo_uno }}</p>
        </span>
        <v-col cols="12" sm="9" v-if="promocionados.length > 0">
          <v-row>
            <v-col cols="12" sm="6" v-for="(item, key) in promocionados" :key="key">
              <boxCurso
                :item="item"
                :max="'220'"
              ></boxCurso>
            </v-col>
          </v-row>
        </v-col>
        <!--  -->
        <span class="px-4 mt-6 text-center py-6 medium font-size-sub-title">
          <div class="d-flex justify-center">
            <div class="text-center">
              <v-img src="../../assets/images/house contacto.svg" width="100%"></v-img>
            </div>
            <div v-html="direccion">
            </div>
          </div>
          <div>
            <v-spacer class="mb-10"></v-spacer>
            <v-avatar size="40" tile>
              <v-img src="../../assets/images/call contacto.svg"></v-img>
            </v-avatar>{{ phone }} - {{ whatsapp }}
            <v-spacer class="mb-10"></v-spacer>
          </div>
        </span>
        
      </v-card>
    </section>
    <!--  -->
    <section id="ofrecemos">
      <v-container>
        <v-row>
          <v-col cols="12" class="mt-5">
            <v-card color="transparent" flat>
              <v-container>
                <v-row>
                  <v-col cols="12" class="white--text text-center">
                    <p class="font-weight-bold display-1-text">Ofrecemos</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="4"
                    v-for="item in arrayOfrecemos"
                    :key="item.title"
                    class="white--text"
                  >
                    <v-row align="center">
                      <v-col cols="2" sm="2" md="2">
                        <v-avatar size="40" tile>
                          <v-img :src="URL_IMG+item.img" contain></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols="10" sm="10" md="10" class="font-weight-bold">{{ item.title }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn color="#302A2C" class="white--text elevation-0" @click="benefit">VER MÁS</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <!--  -->
    <section id="certificacion_oficial" class="my-10">
      <v-container>
        <v-row>
          <v-col cols="12" class="text-center justify-center py-6">
            <p class="font-weight-bold display-1-text">{{titulo_dos}}</p>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="4"
            v-for="(item, key) in oficiales"
            :key="key"
            class="d-flex align-stretch"
          >
          <boxCurso
            :item="item"
            :max="'220'"
          ></boxCurso>
            
          </v-col>
        </v-row>
      </v-container>
    </section>
    <!--  -->
    <!-- <section id="otras_actividades">
      <v-container class="bg-brocha">
        <v-row>
          <v-col cols="12" class="text-center justify-center py-6">
            <p class="font-weight-bold display-1-text">Otras actividades</p>
          </v-col>
          <v-col cols="12" sm="6" md="3" v-for="item in itemCourse" :key="item.id">
            <v-card class="mx-auto px-0 shadow-small" @click="pament_course(item)">
              <v-img
                class="white--text align-end"
                height="150px"
                :src="`https://int-idipv2.institutoidip.com/${item.img}`"
              ></v-img>

              <v-card-subtitle
                class="pb-0 pt-1 text-uppercase black--text font-weight-bold"
              >{{ item.name }}</v-card-subtitle>
              <v-card-subtitle
                class="pt-1 pb-0 black--text font-weight-bold caption"
              >{{ item.duracion }} meses</v-card-subtitle>
             
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>  -->
    <!--  -->
    <section id="map">
      <v-container fluid class="py-0 px-0">
        <v-row>
          <v-col cols="12" v-html="mapUbication">
            
          </v-col>
        </v-row>
      </v-container>
    </section>
    <!--  -->
    <section id="estudiantes">
      <v-container fluid class="px-0 py-0">
        <v-row justify="center">
          <v-col cols="12" class="text-center justify-center py-6">
            <p class="font-weight-bold display-1">¿ Qué dicen nuestros alumnos ?</p>
          </v-col>
          <v-col cols="12">
            <v-slide-group v-model="model" class show-arrows>
              <v-slide-item v-for="(n, key) in comment" :key="key">
                <v-card class="ma-2 shadow-small" style="width: 28.5vmax">
                  <v-card-text class="px-5">
                    <v-row align="center">
                      <v-col cols="12" sm="6" md="2">
                        <v-avatar>
                          <img :src="`${URL_IMG}${n.img}`" alt="John" />
                        </v-avatar>
                      </v-col>
                      <v-col cols="12" sm="6" md="10" class="pl-6">
                        <p class="font-weight-bold black--text subtitle-1">{{n.name}}</p>
                      </v-col>
                      <v-col cols="12" v-html="n.description">
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <!--  -->
  </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import boxCurso from '../../components/boxCurso.vue'
export default {
  data() {
    return {
      model: null,
      tab: null,
      items_certification: [
        {
          img: "images/clock.svg",
          icon: "mdi-eye",
          title: "Cursos presenciales o a distancia",
        },
        {
          img: "images/clock.svg",
          icon: "mdi-eye",
          title: "Jornadas y Seminarios",
        },
        {
          img: "images/clock.svg",
          icon: "mdi-clock-outline",
          title: "Cursos regulares y también para asistir un sábado por mes",
        },
        {
          img: "images/suitcase.svg",
          icon: "mdi-eye",
          title: "Capacitación a empresas y en empresas",
        },
        {
          img: "images/sand-clock.svg",
          icon: "mdi-eye",
          title: "Cursos todos los meses del año",
        },
        {
          img: "images/Forma -2.svg",
          icon: "mdi-eye",
          title: "Cursos personalizados individuales o grupales",
        },
      ],
      mobile: false,
      heig: '200px'
    };
  },
  metaInfo: {
    meta: [
      { name: 'description', content: 'Brindamos la opción de incorporarse al instituto en cualquier mes del año. Damos la posibilidad de cursar una vez por semana, quincenalmente y una vez por semana' }
    ]
  },
  components: {
    boxCurso
  },
  computed: {
    ...mapState('home',['cursos','slider','comment']),
    ...mapState(['URL_IMG']),
    ...mapGetters('home',['promocionados','oficiales','arrayOfrecemos','direccion','mapUbication','phone','whatsapp','titulo_uno','titulo_dos']),
    itemCourse() {
      return this.$store.getters["course/ITEMS_COURSES"];
    },
  },
  created() {
    window.scrollTo(0,0)
     if (screen.width > 992){
      // this.heig = '500px'
     }else{
      this.mobile = true
     }
  },
  methods: {
    image(element){
      if (this.mobile && element.img_mobile != null && element.img_mobile != ''){
        return element.img_mobile
      }
        return element.img
    },
    pament_course(item) {
      this.$router.push({
        name: "payment_course",
        params: { course_id: item.id },
      });
    },
    itemsTabsPromocionales(indice) {
      if (indice === 0) {
        return this.itemCourse.filter((index) => {
          return index.areas_id == 2;
        });
      } else if (indice === 1) {
        return this.itemCourse.filter((index) => {
          return index.areas_id == 1;
        });
      }
    },
    benefit() {
      this.$router.push({ name: "benefit" });
    },
    
  },
};
</script>
<style>
/*.carousel .v-window-item{
  height: 200px !important;
}*/
/*@media(min-width: 992px){
  .carousel .v-window-item{
    height: inherit !important;
  }
}*/
.decoration-through {
  text-decoration: line-through;
}

#ofrecemos {
  background-color: #5ebd99;
}

.shadow-small {
  box-shadow: 0 2px 4px rgba(27, 31, 35, 0.15) !important;
}

.zoom-icon:hover {
  height: 75px;
  width: 75px;
  transition: 0.3s;
}
</style>
